import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css'
import { navigate } from 'gatsby';

const Privacy = () => {
    useEffect(() => {
        navigate('https://www.notion.so/Privacy-Policy-04cb762fa34f4f2ca246172de220cab4')
    }, [])
    return(<div />)
}

export default Privacy;
